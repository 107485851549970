exports.components = {
  "component---src-pages-amauri-costa-toscana-rosso-igp-js": () => import("./../../../src/pages/amauri-costa-toscana-rosso-igp.js" /* webpackChunkName: "component---src-pages-amauri-costa-toscana-rosso-igp-js" */),
  "component---src-pages-amauri-grand-cru-double-belgian-beer-3-x-033-l-js": () => import("./../../../src/pages/amauri-grand-cru-double-belgian-beer-3-x-033l.js" /* webpackChunkName: "component---src-pages-amauri-grand-cru-double-belgian-beer-3-x-033-l-js" */),
  "component---src-pages-amauri-js": () => import("./../../../src/pages/amauri.js" /* webpackChunkName: "component---src-pages-amauri-js" */),
  "component---src-pages-amauri-malvasia-bianca-igp-js": () => import("./../../../src/pages/amauri-malvasia-bianca-igp.js" /* webpackChunkName: "component---src-pages-amauri-malvasia-bianca-igp-js" */),
  "component---src-pages-amauri-malvasia-nera-fortificata-js": () => import("./../../../src/pages/amauri-malvasia-nera-fortificata.js" /* webpackChunkName: "component---src-pages-amauri-malvasia-nera-fortificata-js" */),
  "component---src-pages-amauri-malvasia-nera-igp-js": () => import("./../../../src/pages/amauri-malvasia-nera-igp.js" /* webpackChunkName: "component---src-pages-amauri-malvasia-nera-igp-js" */),
  "component---src-pages-amauri-nero-davola-doc-js": () => import("./../../../src/pages/amauri-nero-davola-doc.js" /* webpackChunkName: "component---src-pages-amauri-nero-davola-doc-js" */),
  "component---src-pages-amoroso-js": () => import("./../../../src/pages/amoroso.js" /* webpackChunkName: "component---src-pages-amoroso-js" */),
  "component---src-pages-amoroso-susumaniello-igp-puglia-js": () => import("./../../../src/pages/amoroso-susumaniello-igp-puglia.js" /* webpackChunkName: "component---src-pages-amoroso-susumaniello-igp-puglia-js" */),
  "component---src-pages-amoroso-verdeca-puglia-igp-js": () => import("./../../../src/pages/amoroso-verdeca-puglia-igp.js" /* webpackChunkName: "component---src-pages-amoroso-verdeca-puglia-igp-js" */),
  "component---src-pages-cafu-js": () => import("./../../../src/pages/cafu.js" /* webpackChunkName: "component---src-pages-cafu-js" */),
  "component---src-pages-cafu-salento-rosso-primitivo-malvasia-nera-igp-js": () => import("./../../../src/pages/cafu-salento-rosso-primitivo-malvasia-nera-igp.js" /* webpackChunkName: "component---src-pages-cafu-salento-rosso-primitivo-malvasia-nera-igp-js" */),
  "component---src-pages-candela-js": () => import("./../../../src/pages/candela.js" /* webpackChunkName: "component---src-pages-candela-js" */),
  "component---src-pages-candela-negroamaro-merlot-salento-igp-js": () => import("./../../../src/pages/candela-negroamaro-merlot-salento-igp.js" /* webpackChunkName: "component---src-pages-candela-negroamaro-merlot-salento-igp-js" */),
  "component---src-pages-candela-primitivo-rosato-igp-js": () => import("./../../../src/pages/candela-primitivo-rosato-igp.js" /* webpackChunkName: "component---src-pages-candela-primitivo-rosato-igp-js" */),
  "component---src-pages-candela-verdeca-puglia-bianco-igp-js": () => import("./../../../src/pages/candela-verdeca-puglia-bianco-igp.js" /* webpackChunkName: "component---src-pages-candela-verdeca-puglia-bianco-igp-js" */),
  "component---src-pages-carlos-bianco-salento-igp-js": () => import("./../../../src/pages/carlos-bianco-salento-igp.js" /* webpackChunkName: "component---src-pages-carlos-bianco-salento-igp-js" */),
  "component---src-pages-carlos-js": () => import("./../../../src/pages/carlos.js" /* webpackChunkName: "component---src-pages-carlos-js" */),
  "component---src-pages-carlos-negroamaro-salento-igp-js": () => import("./../../../src/pages/carlos-negroamaro-salento-igp.js" /* webpackChunkName: "component---src-pages-carlos-negroamaro-salento-igp-js" */),
  "component---src-pages-carlos-nero-davola-doc-js": () => import("./../../../src/pages/carlos-nero-davola-doc.js" /* webpackChunkName: "component---src-pages-carlos-nero-davola-doc-js" */),
  "component---src-pages-carlos-salento-rosato-igp-js": () => import("./../../../src/pages/carlos-salento-rosato-igp.js" /* webpackChunkName: "component---src-pages-carlos-salento-rosato-igp-js" */),
  "component---src-pages-cesar-js": () => import("./../../../src/pages/cesar.js" /* webpackChunkName: "component---src-pages-cesar-js" */),
  "component---src-pages-cesar-salento-rosso-igp-js": () => import("./../../../src/pages/cesar-salento-rosso-igp.js" /* webpackChunkName: "component---src-pages-cesar-salento-rosso-igp-js" */),
  "component---src-pages-chevanton-js": () => import("./../../../src/pages/chevanton.js" /* webpackChunkName: "component---src-pages-chevanton-js" */),
  "component---src-pages-chevanton-salento-rosso-igp-negroamaro-primitivo-malvasia-js": () => import("./../../../src/pages/chevanton-salento-rosso-igp-negroamaro-primitivo-malvasia.js" /* webpackChunkName: "component---src-pages-chevanton-salento-rosso-igp-negroamaro-primitivo-malvasia-js" */),
  "component---src-pages-fabio-cordella-js": () => import("./../../../src/pages/fabio-cordella.js" /* webpackChunkName: "component---src-pages-fabio-cordella-js" */),
  "component---src-pages-fotogalerie-js": () => import("./../../../src/pages/fotogalerie.js" /* webpackChunkName: "component---src-pages-fotogalerie-js" */),
  "component---src-pages-frey-js": () => import("./../../../src/pages/frey.js" /* webpackChunkName: "component---src-pages-frey-js" */),
  "component---src-pages-frey-malvasia-igp-salento-rosato-js": () => import("./../../../src/pages/frey-malvasia-igp-salento-rosato.js" /* webpackChunkName: "component---src-pages-frey-malvasia-igp-salento-rosato-js" */),
  "component---src-pages-frey-pinot-grigio-igp-puglia-js": () => import("./../../../src/pages/frey-pinot-grigio-igp-puglia.js" /* webpackChunkName: "component---src-pages-frey-pinot-grigio-igp-puglia-js" */),
  "component---src-pages-frey-salento-primitivo-merlot-igp-js": () => import("./../../../src/pages/frey-salento-primitivo-merlot-igp.js" /* webpackChunkName: "component---src-pages-frey-salento-primitivo-merlot-igp-js" */),
  "component---src-pages-galliani-fiano-bianco-igp-puglia-js": () => import("./../../../src/pages/galliani-fiano-bianco-igp-puglia.js" /* webpackChunkName: "component---src-pages-galliani-fiano-bianco-igp-puglia-js" */),
  "component---src-pages-galliani-js": () => import("./../../../src/pages/galliani.js" /* webpackChunkName: "component---src-pages-galliani-js" */),
  "component---src-pages-galliani-negroamaro-rosso-igp-salento-js": () => import("./../../../src/pages/galliani-negroamaro-rosso-igp-salento.js" /* webpackChunkName: "component---src-pages-galliani-negroamaro-rosso-igp-salento-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jankulovski-js": () => import("./../../../src/pages/jankulovski.js" /* webpackChunkName: "component---src-pages-jankulovski-js" */),
  "component---src-pages-jankulovski-rosso-salento-igp-malvasia-merlot-js": () => import("./../../../src/pages/jankulovski-rosso-salento-igp-malvasia-merlot.js" /* webpackChunkName: "component---src-pages-jankulovski-rosso-salento-igp-malvasia-merlot-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kuranyi-js": () => import("./../../../src/pages/kuranyi.js" /* webpackChunkName: "component---src-pages-kuranyi-js" */),
  "component---src-pages-kuranyi-negroamaro-e-susumaniello-igp-puglia-js": () => import("./../../../src/pages/kuranyi-negroamaro-e-susumaniello-igp-puglia.js" /* webpackChunkName: "component---src-pages-kuranyi-negroamaro-e-susumaniello-igp-puglia-js" */),
  "component---src-pages-lugano-js": () => import("./../../../src/pages/lugano.js" /* webpackChunkName: "component---src-pages-lugano-js" */),
  "component---src-pages-lugano-salento-rosso-igp-js": () => import("./../../../src/pages/lugano-salento-rosso-igp.js" /* webpackChunkName: "component---src-pages-lugano-salento-rosso-igp-js" */),
  "component---src-pages-materazzi-copertino-dop-rosso-js": () => import("./../../../src/pages/materazzi-copertino-dop-rosso.js" /* webpackChunkName: "component---src-pages-materazzi-copertino-dop-rosso-js" */),
  "component---src-pages-materazzi-js": () => import("./../../../src/pages/materazzi.js" /* webpackChunkName: "component---src-pages-materazzi-js" */),
  "component---src-pages-materazzi-salento-chardonnay-barricato-igp-js": () => import("./../../../src/pages/materazzi-salento-chardonnay-barricato-igp.js" /* webpackChunkName: "component---src-pages-materazzi-salento-chardonnay-barricato-igp-js" */),
  "component---src-pages-materazzi-salento-igp-primitivo-rosato-js": () => import("./../../../src/pages/materazzi-salento-igp-primitivo-rosato.js" /* webpackChunkName: "component---src-pages-materazzi-salento-igp-primitivo-rosato-js" */),
  "component---src-pages-ochoa-js": () => import("./../../../src/pages/ochoa.js" /* webpackChunkName: "component---src-pages-ochoa-js" */),
  "component---src-pages-ochoa-primitivo-e-cabernet-igp-salento-js": () => import("./../../../src/pages/ochoa-primitivo-e-cabernet-igp-salento.js" /* webpackChunkName: "component---src-pages-ochoa-primitivo-e-cabernet-igp-salento-js" */),
  "component---src-pages-ronaldinho-brunello-di-montalcino-js": () => import("./../../../src/pages/ronaldinho-brunello-di-montalcino.js" /* webpackChunkName: "component---src-pages-ronaldinho-brunello-di-montalcino-js" */),
  "component---src-pages-ronaldinho-js": () => import("./../../../src/pages/ronaldinho.js" /* webpackChunkName: "component---src-pages-ronaldinho-js" */),
  "component---src-pages-ronaldinho-negroamaro-rosato-igp-js": () => import("./../../../src/pages/ronaldinho-negroamaro-rosato-igp.js" /* webpackChunkName: "component---src-pages-ronaldinho-negroamaro-rosato-igp-js" */),
  "component---src-pages-ronaldinho-nero-davola-doc-js": () => import("./../../../src/pages/ronaldinho-nero-davola-doc.js" /* webpackChunkName: "component---src-pages-ronaldinho-nero-davola-doc-js" */),
  "component---src-pages-ronaldinho-primitivo-fortificato-js": () => import("./../../../src/pages/ronaldinho-primitivo-fortificato.js" /* webpackChunkName: "component---src-pages-ronaldinho-primitivo-fortificato-js" */),
  "component---src-pages-ronaldinho-prosecco-doc-js": () => import("./../../../src/pages/ronaldinho-prosecco-doc.js" /* webpackChunkName: "component---src-pages-ronaldinho-prosecco-doc-js" */),
  "component---src-pages-ronaldinho-salento-chardonnay-salento-igp-js": () => import("./../../../src/pages/ronaldinho-salento-chardonnay-salento-igp.js" /* webpackChunkName: "component---src-pages-ronaldinho-salento-chardonnay-salento-igp-js" */),
  "component---src-pages-ronaldinho-salento-primitivo-igp-js": () => import("./../../../src/pages/ronaldinho-salento-primitivo-igp.js" /* webpackChunkName: "component---src-pages-ronaldinho-salento-primitivo-igp-js" */),
  "component---src-pages-sneijder-chardonnay-salento-igp-js": () => import("./../../../src/pages/sneijder-chardonnay-salento-igp.js" /* webpackChunkName: "component---src-pages-sneijder-chardonnay-salento-igp-js" */),
  "component---src-pages-sneijder-js": () => import("./../../../src/pages/sneijder.js" /* webpackChunkName: "component---src-pages-sneijder-js" */),
  "component---src-pages-sneijder-negroamaro-rose-igp-js": () => import("./../../../src/pages/sneijder-negroamaro-rose-igp.js" /* webpackChunkName: "component---src-pages-sneijder-negroamaro-rose-igp-js" */),
  "component---src-pages-sneijder-salento-rosso-igp-js": () => import("./../../../src/pages/sneijder-salento-rosso-igp.js" /* webpackChunkName: "component---src-pages-sneijder-salento-rosso-igp-js" */),
  "component---src-pages-terry-chardonnay-igp-salento-js": () => import("./../../../src/pages/terry-chardonnay-igp-salento.js" /* webpackChunkName: "component---src-pages-terry-chardonnay-igp-salento-js" */),
  "component---src-pages-terry-js": () => import("./../../../src/pages/terry.js" /* webpackChunkName: "component---src-pages-terry-js" */),
  "component---src-pages-terry-rosato-primitivo-igp-salento-js": () => import("./../../../src/pages/terry-rosato-primitivo-igp-salento.js" /* webpackChunkName: "component---src-pages-terry-rosato-primitivo-igp-salento-js" */),
  "component---src-pages-terry-salice-salentino-dop-js": () => import("./../../../src/pages/terry-salice-salentino-dop.js" /* webpackChunkName: "component---src-pages-terry-salice-salentino-dop-js" */),
  "component---src-pages-zamorano-js": () => import("./../../../src/pages/zamorano.js" /* webpackChunkName: "component---src-pages-zamorano-js" */),
  "component---src-pages-zamorano-nero-davola-doc-js": () => import("./../../../src/pages/zamorano-nero-davola-doc.js" /* webpackChunkName: "component---src-pages-zamorano-nero-davola-doc-js" */),
  "component---src-pages-zamorano-salento-negroamaro-igp-js": () => import("./../../../src/pages/zamorano-salento-negroamaro-igp.js" /* webpackChunkName: "component---src-pages-zamorano-salento-negroamaro-igp-js" */)
}

